import React from "react"
import { graphql, Link } from "gatsby"

import { GlobalStyle } from "../styles"
import SEO from "../components/seo"

import Nav from "../segments/Nav"
import Footer from "../segments/Footer"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <>
      <GlobalStyle />
      <SEO title={`${frontmatter.title} | React Native by Example`} />
      <Nav isFixedTop={false} />
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-8 is-offset-2">
              <div className="content is-medium">
                <h1 className="title">{frontmatter.title}</h1>
                {/* <h2 className="subtitle">{frontmatter.date}</h2> */}
                <div dangerouslySetInnerHTML={{ __html: html }} />
              </div>

              <section
                className="columns is-vcentered notification"
                style={{
                  backgroundColor: "#00d1b2",
                  color: "#fff",
                }}
              >
                <figure className="column image is-centered">
                  <img
                    src={require("../images/logo.png")}
                    alt="React Native by Example Logo"
                  />
                </figure>
                <div className="column is-three-quarters">
                  <h3 className="title is-3">React Native by Example</h3>
                  <p className="subtitle">
                    Become an expert with React Native by building 10 completely
                    unique apps. Nothing beats learning by doing.
                  </p>
                  <div className="buttons">
                    <Link className="button" to="/">
                      <span>Learn More</span>
                      {/* <span className="icon is-small">
                        <i className="fas fa-chevron-right" />
                      </span> */}
                    </Link>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}
export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`
